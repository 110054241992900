// import PProviderService from '@/services/api/Provider/PProvider'
// import RulesService from '@/services/rules'

export default {
  name: 'v-view-account',
  data() {
    return {
      gUserAccount: {},
    }
  },
  methods: {

    /**
     * 
     */
    getUserAccount() {
      this.gUserAccount = this.$store.state.UserModule.data
    },
  },
  mounted() {
    this.getUserAccount()
  },
}